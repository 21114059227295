import React from 'react';
import PropTypes from 'prop-types';
import AuthContext from "../../../contexts/authContext";
import DealerUserProfileContext from "../../../contexts/dealerUserProfileContext";
import LoadingDialog from "../../atoms/LoadingDialog";

const LoginProviderFactory = React.lazy(() => import( "../../organisms/LoginProviderFactory"))
const FlexiTemplate = React.lazy(() => import( "../FlexiTemplate"))
const DealerUserProfileProvider = React.lazy(() => import( "../../organisms/DealerUserProfileProvider"))
const CustomerAppBar = React.lazy(() => import( '../../molecules/CustomerAppBar'))

const TemplateConfigurator = ({children}) => {
    const {logout: onLogout} = React.useContext(AuthContext);
    const user = React.useContext(DealerUserProfileContext);

    return (
        <FlexiTemplate onLogout={onLogout} user={user}>
            <CustomerAppBar
                user={user}
                onLogout={onLogout}
            />
            {children}
        </FlexiTemplate>
    )
}
TemplateConfigurator.propTypes = {
    children: PropTypes.node,
}

const CustomerLoggedInFlexiTemplate = ({
                                           method, endpoint, children
                                       }) => {
    return (
        <React.Suspense fallback={<LoadingDialog/>}>
            <LoginProviderFactory method={method} endpoint={endpoint}>
                <DealerUserProfileProvider>
                    <TemplateConfigurator>{children}</TemplateConfigurator>
                </DealerUserProfileProvider>
            </LoginProviderFactory>
        </React.Suspense>
    )

}
CustomerLoggedInFlexiTemplate.propTypes = {
    chidren: PropTypes.node
}


export default CustomerLoggedInFlexiTemplate
export {CustomerLoggedInFlexiTemplate}