import * as React from 'react';

import FlexiThemeProvider from "./ThemeProvider";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import LoadingDialog from "./atomic/atoms/LoadingDialog";
import ScrollToTop from "./atomic/templates/LoggedInFlexiTemplate/scrollToTop";
import CustomerLoggedInFlexiTemplate from "./atomic/templates/CustomerLoggedInFlexiTemplate";
import CustomerClaimQuotePage from "./atomic/pages/CustomerSite/CustomerClaimQuotePage";

const CustomerQuoteListPage = React.lazy(() => import( "./atomic/pages/CustomerSite/QuoteListPage"))
const QuoteOnboardingDetailsPage = React.lazy(() => import( "./atomic/pages/CustomerSite/CustomerQuoteOnboardingDetailsPage"))
const QuoteOnboardingExtrasPage = React.lazy(() => import( "./atomic/pages/CustomerSite/CustomerQuoteOnboardingExtrasPage"))
const QuoteOnboardingDocumentsPage = React.lazy(() => import( "./atomic/pages/CustomerSite/CustomerQuoteOnboardingDocumentsPage"))
const QuoteOnboardingSummaryPage = React.lazy(() => import( "./atomic/pages/QuoteOnboardingSummaryPage"))
const CustomerPaymentSuccessPage = React.lazy(() => import( "./atomic/pages/CustomerSite/CustomerPaymentSuccessPage"))


const LoggedInRoutes = () => {
    const NotFoundPage = React.lazy(() => import ("./atomic/pages/NotFoundPage"))


    const router = createBrowserRouter([
        {
            path: "/",
            element: <>
                <ScrollToTop/><CustomerQuoteListPage/></>,
            errorElement: <NotFoundPage/>,
        },

        {
            path: "/quotes/",
            element: <>
                <ScrollToTop/><CustomerQuoteListPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId",
            element: <>
                <ScrollToTop/><CustomerQuoteListPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding",
            element: <>
                <ScrollToTop/><QuoteOnboardingDetailsPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/details",
            element: <>
                <ScrollToTop/><QuoteOnboardingDetailsPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/extras",
            element: <>
                <ScrollToTop/><QuoteOnboardingExtrasPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/documents",
            element: <>
                <ScrollToTop/><QuoteOnboardingDocumentsPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/summary",
            element: <>
                <ScrollToTop/><QuoteOnboardingSummaryPage enablePayment/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/complete/:sessionId",
            element: <>
                <ScrollToTop/><CustomerPaymentSuccessPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/claim/:quoteId/:action",
            element: <>
                <ScrollToTop/><CustomerClaimQuotePage/></>,
            errorElement: <NotFoundPage/>,
        }
    ]);

    return (
        <React.Suspense fallback={<LoadingDialog/>}>
            <RouterProvider router={router}/>
        </React.Suspense>
    )
}

function CustomerApp() {
    return (
        <FlexiThemeProvider>
            <CustomerLoggedInFlexiTemplate method={process.env.REACT_APP_FLEXI_AUTH_METHOD}
                                           endpoint={process.env.REACT_APP_FLEXI_BACKEND_URL}>
                <LoggedInRoutes/>
            </CustomerLoggedInFlexiTemplate>
        </FlexiThemeProvider>
    );
}

export default CustomerApp;
