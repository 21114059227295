import React from 'react';
import ReactDOM from 'react-dom/client';
import DealerApp from './DealerApp';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import CustomerApp from "./CustomerApp";
const config = {
    Auth: {
        Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
            loginWith: { // Optional
                username: 'false',
                email: 'true', // Optional
                phone: 'false', // Optional
            },
            mandatorySignIn: true,
        },
    },
    Storage: {
        S3: {
            bucket: process.env.REACT_APP_UPLOAD_BUCKET,
            region: 'eu-west-1',
        }
    }
};


Amplify.configure(config);

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(
  <React.StrictMode>
      {process.env.REACT_APP_FLEXI_INTERFACE==='customer' ? (
          <CustomerApp/>
      ):(
          <DealerApp />
      )}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();