import * as React from 'react';
import LoggedInFlexiTemplate from "./atomic/templates/LoggedInFlexiTemplate";
import FlexiThemeProvider from "./ThemeProvider";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import SelectedDealershipContext from "./contexts/selectedDealershipContext";

import LoadingDialog from "./atomic/atoms/LoadingDialog";
import {Amplify} from 'aws-amplify';
import ScrollToTop from "./atomic/templates/LoggedInFlexiTemplate/scrollToTop";


const QuoteNewChooseInventoryPage =React.lazy(()=>import( "./atomic/pages/InventoryListPage"))
const CustomerListPage =React.lazy(()=>import( "./atomic/pages/CustomerListPage"))
const QuoteListPage =React.lazy(()=>import( "./atomic/pages/QuoteListPage"))
const QuoteOnboardingDetailsPage =React.lazy(()=>import( "./atomic/pages/QuoteOnboardingDetailsPage"))
const QuoteOnboardingExtrasPage =React.lazy(()=>import( "./atomic/pages/QuoteOnboardingExtrasPage"))
const QuoteOnboardingDocumentsPage =React.lazy(()=>import( "./atomic/pages/QuoteOnboardingDocumentsPage"))
const QuoteOnboardingSummaryPage =React.lazy(()=>import( "./atomic/pages/QuoteOnboardingSummaryPage"))


const config = {
    Auth: {
        Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
            loginWith: { // Optional
                username: 'false',
                email: 'true', // Optional
                phone: 'false', // Optional
            },
            mandatorySignIn: true,
        },
    },
    Storage: {
        S3: {
            bucket: 'api-dealer-staging-flexi-com-dealeruploadbucket-wtubk5dyvowb',
            region: 'eu-west-1',
        }
    }
};


Amplify.configure(config);


const LoggedInRoutes = () => {
    const dealerContext = React.useContext(SelectedDealershipContext)

    const NotFoundPage = React.lazy(() => import ("./atomic/pages/NotFoundPage"))


    const router = createBrowserRouter([
        {
            path: "/",
            element: <>
                <ScrollToTop/>
                <QuoteNewChooseInventoryPage dealer={dealerContext.selectedDealership}/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/inventory/",
            element: <>
            <ScrollToTop/><QuoteNewChooseInventoryPage dealer={dealerContext.selectedDealership}/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/customers/",
            element: <>
            <ScrollToTop/><CustomerListPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/",
            element: <>
            <ScrollToTop/><QuoteListPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId",
            element: <>
            <ScrollToTop/><QuoteListPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding",
            element: <>
            <ScrollToTop/><QuoteOnboardingDetailsPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/details",
            element: <>
            <ScrollToTop/><QuoteOnboardingDetailsPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/extras",
            element: <>
            <ScrollToTop/><QuoteOnboardingExtrasPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/documents",
            element: <>
            <ScrollToTop/><QuoteOnboardingDocumentsPage/></>,
            errorElement: <NotFoundPage/>,
        },
        {
            path: "/quotes/:quoteId/onboarding/summary",
            element: <>
            <ScrollToTop/><QuoteOnboardingSummaryPage/></>,
            errorElement: <NotFoundPage/>,
        }

    ]);

    return (
        <React.Suspense fallback={<LoadingDialog/>}>
            <RouterProvider router={router}/>
        </React.Suspense>
    )
}

function DealerApp() {
    return (
        <FlexiThemeProvider>
            <LoggedInFlexiTemplate method={process.env.REACT_APP_FLEXI_AUTH_METHOD}
                                   endpoint={process.env.REACT_APP_FLEXI_BACKEND_URL}>
                <LoggedInRoutes/>
            </LoggedInFlexiTemplate>
        </FlexiThemeProvider>
    );
}

export default DealerApp;
