import React from 'react';
import Container from '@mui/material/Container'
import Stack from "@mui/material/Stack";
import {useNavigate, useParams} from "react-router-dom";
import LoadingDialog from "../../../atoms/LoadingDialog";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";


import {gql, useMutation, useQuery} from "@apollo/client";
import LinkQuoteModal from "../../../molecules/LinkQuoteModal";

export const CAN_CLAIM_QUOTE = gql`
    query CAN_CLAIM_QUOTE($quoteId: ID!) {
        quotes {
            canClaimQuote(quoteId: $quoteId) {
                hasClaimed
                canClaim
                name
            }
        }
    }
`
export const CLAIM_QUOTE = gql`
    mutation CLAIM_QUOTE($quoteId: ID!,$dealerName:String!) {
        userProfileUpdate(attributes:[{key:"dealerShareConsent",value:$dealerName}]){
            ok
            errors{
                field
                messages
            }
        }

        dealershipQuoteClaim(quoteId: $quoteId){
            ok
            errors{
                field
                messages
            }
        }
    }
`


export const useCanClaimQuote = ({quoteId}) => {
    const {
        loading: loadingClaim, error: claimError, data: claimData, refetch: refetchClaim,
    } = useQuery(CAN_CLAIM_QUOTE, {
        variables: {
            quoteId,
        }
    });

    return {
        loadingClaim, claimError, claimData, refetchClaim
    }
}

const CustomerClaimQuotePage = ({enablePayment = false}) => {
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate()
    const {quoteId, action} = useParams()

    const {loadingClaim, claimError, claimData} = useCanClaimQuote({quoteId})

    const [claimQuote] = useMutation(CLAIM_QUOTE, {
        variables: {quoteId, dealerName: claimData?.quotes?.canClaimQuote?.name},
        refetchQueries: ['GET_DEALER_QUOTE_DETAILS'],
    });

    React.useEffect(() => {
        if (claimData?.quotes?.canClaimQuote?.hasClaimed) {
            navigate(`/quotes/${quoteId}/onboarding/${action}`)
        }
    }, [navigate,action,quoteId,claimData?.quotes?.canClaimQuote?.hasClaimed]);


    return (loadingClaim || loading || claimData?.quotes?.canClaimQuote?.hasClaimed) ? <LoadingDialog/> : (<>
        <Box sx={{
            flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between",
        }}>
            <Container>
                <Stack sx={{pt: 5, pb: 2}} spacing={4}>
                    {claimError ? (
                        <Alert severity="error">
                            <AlertTitle>Unexpected Error!</AlertTitle>
                            The quote could not be retrieved. Please refresh the page, if problems persist, please
                            contact support.
                        </Alert>
                    ) : null}
                    {error ? (
                        <Alert severity="error">
                            <AlertTitle>Unexpected Error!</AlertTitle>
                            The quote could not be retrieved. Please refresh the page, if problems persist, please
                            contact support.
                        </Alert>
                    ) : null}
                </Stack>
                {(!(claimData?.quotes?.canClaimQuote?.canClaim)) ? (
                    <Alert severity="error">
                        <AlertTitle>Quote Not Available!</AlertTitle>
                        The link you are opening is no longer valid, or has been assigned to a new user. If problems
                        persist, please contact support.
                    </Alert>
                ) : null}
            </Container>
            <LinkQuoteModal dealership={claimData?.quotes?.canClaimQuote.name}
                            open={(claimData?.quotes?.canClaimQuote?.canClaim)} onClose={() => navigate("/")}
                            onLink={() => {
                                setLoading(true)
                                setError(null)
                                claimQuote().then(({data}) => {
                                    if (data?.dealershipQuoteClaim?.ok) {
                                        navigate(`/quotes/${quoteId}/onboarding/${action}`)
                                    } else {
                                        setLoading(false)
                                        setError("Unexpected Error!")
                                    }
                                }).catch(({error}) => {
                                    setLoading(false)
                                    setError("Unexpected Error!")
                                })
                            }}/>
        </Box>
    </>)
}

CustomerClaimQuotePage.propTypes = {
    enablePayment: PropTypes.bool,
}


export default CustomerClaimQuotePage
export {CustomerClaimQuotePage}