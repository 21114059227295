import React from 'react';
import PropTypes from 'prop-types';

const AuthContext = React.createContext({
    accessToken: '',
    claims: {},
    logout: () => Promise.resolve(),
    isAuthenticated: false, 
    authenticating: false,
    authError: null,
});

export {AuthContext};
export default AuthContext

AuthContext.propTypes = {
    accessToken: PropTypes.string.isRequired, // The access token used by Apollo Client, just the bearer token itself, not the whole JWT.
    claims: PropTypes.shape({}).isRequired, // Dictionary of any claims as key value pairs
    logout: PropTypes.func.isRequired, // Function which returns a promise, when called logs out the user.
    isAuthenticated: PropTypes.bool.isRequired,// True if ther user is signed in.
    authenticating: PropTypes.bool.isRequired, // True if the authentication process is ongoing
    authError: PropTypes.string, // True if there is an authorization error
}

