import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';

const LoadingDialog = () => {
  return (
    <Backdrop TransitionComponent={Fade} open sx={{
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:'#ffffff',
      zIndex:10
    }}>
      <CircularProgress color="primary" size={'10vh'}/>
    </Backdrop>
  );
};

LoadingDialog.propTypes = {};

export { LoadingDialog };
export default LoadingDialog;
