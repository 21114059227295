import IconButton from "@mui/material/IconButton"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React from "react"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

export const OnCloseDialogTitle = (props) => {
    const {children, onClose, extraIcon, ...other} = props

    return (
        <Stack alignItems={"center"} sx={{m: 0, p: 2, textAlign: "center"}} justifyContent={"center"}>
            <Box   {...other}>
                <Typography sx={{pl: 10, pr: 10, fontSize: "1.4rem"}}>{children}</Typography>
                <Divider sx={{mt: 2}} color={"primary"}/>
            </Box>
            {extraIcon ? (
                <IconButton
                    disabled
                    sx={{
                        position: "absolute",
                        left: 8,
                        top: 8,
                        color: "primary"
                    }}
                >
                    {extraIcon}
                </IconButton>
            ) : null}
            {onClose ? (
                <IconButton
                    disabled={props?.loading}
                    title={"Close"}
                    aria-label="close"
                    onClick={!props.loading ? onClose : undefined}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "primary"
                    }}
                >
                    <HighlightOffIcon fontSize={"large"}/>
                </IconButton>
            ) : null}

        </Stack>
    )
}

OnCloseDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func
}