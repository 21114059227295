import React from 'react';
import PropTypes from 'prop-types';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';
import ClosableDialog from "../../atoms/ClosableDialog";
import {OnCloseDialogTitle} from "../../atoms/ClosableDialogTitle";
import DialogContent from '@mui/material/DialogContent'
import {useTheme} from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";


const LinkQuoteModal = ({
                                  open,
                                  onClose,
                                  onLink,
                                  dealership,
                              }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <ClosableDialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth={"md"}>
            <OnCloseDialogTitle onClose={onClose}>
                Add Quote
            </OnCloseDialogTitle>

            <DialogContent>
                <Stack spacing={2}>
                    <Typography>
                        Before we can access your quote, we need your permission.
                    </Typography>
                    <Typography>When you link this quote, any information you provide as part of the onboarding process
                        will be shared with {dealership}.</Typography>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction={"row"} sx={{width: "100%"}} justifyContent="center">
                    <Button color="primary" variant={"contained"} onClick={onLink} fullWidth>Link Quote</Button>
                </Stack>
            </DialogActions>
        </ClosableDialog>
    );
};

LinkQuoteModal.propTypes = {
    leasePriceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    interiorColor: PropTypes.shape({name: PropTypes.string.isRequired}).isRequired,
    exteriorColor: PropTypes.shape({name: PropTypes.string.isRequired}).isRequired,
    onClose: PropTypes.func.isRequired,
    onClickNewQuote: PropTypes.func.isRequired,
};

export default LinkQuoteModal;
export {
    LinkQuoteModal
};
